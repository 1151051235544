import React, { useState } from "react";
import { toast } from "react-toastify";
import { useStaticQuery, graphql } from "gatsby";
import Buttons from "../../components/bouton/button";
import { getMoreInfo } from "../../api/api";

interface InfoUser {
    nom: string;
    email: string;
    message: string;
}

export default function Infos() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "contact-infos" } }
                        page: { code: { _eq: "contact" } }
                    }
                ) {
                    text
                    id
                }
            }
        }
    `);

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    const initialState = {
        nom: "",
        email: "",
        message: "",
    };

    const [state, setState] = useState<InfoUser>(initialState);

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSend = (e: any) => {
        e.preventDefault();

        if (!state.nom || !state.email || !state.message) {
            toast.error("Veuillez renseigner tous les champs !", {
                position: "top-right",
                autoClose: 4000,
            });
        } else {
            getMoreInfo({
                nom: state.nom,
                email: state.email,
                message: state.message,
            })
                .then((res) => {
                    let data = res.data;
                    if (data.success) {
                        setState(initialState);

                        toast.info("Votre message a bien été envoyée.", {
                            position: "top-right",
                            autoClose: 4000,
                        });
                    } else {
                        toast.error("L'envoi n'a pas abouti !", {
                            position: "top-right",
                            autoClose: 4000,
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    return (
        <div className="tw-bg-bgColor">
            <div className="tw-flex tw-items-center tw-justify-center tw-text-center tw-py-4">
                <div
                    className="tw-p-5 custom-content tw-max-w-3xl tw-text-center"
                    dangerouslySetInnerHTML={{ __html: content?.text }}
                    // dangerouslySetInnerHTML={{
                    //     __html: `<p class="tw-text-md sm:tw-text-2xl tw-font-bold tw-mb-3">Plus d'informations</p>
                    // <div class="tw-w-full tw-flex tw-justify-center">
                    // <p class="tw-text-xs tw-w-4/5 tw-text-justify tw-mb-4 sm:tw-text-center sm:tw-text-sm">Pour tout besoin d'informations compl&eacute;mentaires sur la "nouvelle Adresse", le "demande de certificat" ou toute autre question veuillez nous contacter en remplissant le champ ci-dessous.<br>Nous ne manquerons pas de vous r&eacute;pondre dans les plus brefs d&eacute;lais.</p>
                    // </div>`,
                    // }}
                ></div>
            </div>

            <div className="tw-w-full tw-flex tw-justify-center">
                <div className=" tw-flex tw-flex-col tw-w-80 ">
                    <input
                        onChange={handleChange}
                        value={state.nom}
                        name="nom"
                        type="text"
                        placeholder="Nom & Prénom"
                        className="tw-input p-3 tw-input-sm tw-input-bordered tw-w-full tw-max-w-xs tw-rounded-none focus:tw-outline-none focus:tw-ring-0"
                    />
                    <input
                        onChange={handleChange}
                        value={state.email}
                        name="email"
                        type="text"
                        placeholder="Adresse mail"
                        className="tw-input p-3  tw-input-sm tw-input-bordered tw-w-full tw-max-w-xs tw-rounded-none focus:tw-outline-none focus:tw-ring-0"
                    />
                    <textarea
                        onChange={handleChange}
                        value={state.message}
                        name="message"
                        className="tw-textarea p-3  tw-textarea-bordered tw-mb-4 tw-rounded-none focus:tw-outline-none focus:tw-ring-0"
                        placeholder="Message"
                    ></textarea>
                    <div className="tw-flex tw-justify-start tw-pb-9 ">
                        <Buttons
                            onClick={(e: any) => handleSend(e)}
                            textButton="Envoyé"
                            classN="tw-btn tw-border-0 tw-btn-sm tw-font-normal tw-normal-case tw-text-md tw-font-semibold tw-w-30 lg:tw-px-4 tw-rounded-lg sm:tw-w-30  tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
