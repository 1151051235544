import React from "react";
import { Button } from "react-daisyui";

interface prop {
    textButton: string
    classN: any
    onClick?: any
}

const Buttons = ({textButton,classN, onClick}: prop) => {
  return (
    <button
        onClick={(e) => onClick(e)}
      className={classN}
    >
      {textButton}
    </button>
  );
};

export default Buttons;
