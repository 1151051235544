import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import File from "../file";

export default function Contacts() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "contact-contacts" } }
                        page: { code: { _eq: "contact" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    return (
        <div className="tw-flex tw-flex-wrap tw-h-[430px]">
            <div
                className="tw-p-3 tw-w-full sm:tw-w-7/12 tw-flex tw-flex-col tw-justify-center"
                style={{ backgroundColor: "#46965E" }}
            >
                <div className="tw-block tw-ml-auto tw-mr-auto tw-p-5 tw-text-white">
                    <div
                        className="tw-p-5 tw-text-white"
                        dangerouslySetInnerHTML={{ __html: content?.text }}
                    ></div>
                </div>
            </div>
            <div
                className="tw-hidden sm:tw-w-5/12 sm:tw-flex tw-justify-center tw-items-center tw-h-[430px]"
                style={{ backgroundColor: "#097433" }}
            >
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="tw-h-[430px]"
                />
            </div>
        </div>
    );
}
