import React from "react";
import Buttons from "../components/bouton/button";
import Partner from "../components/partner/partner";
import Layout from "../layouts/layout";

import Contact from "../components/contact/contacts";
import Infos from "../components/contact/infos";

const Contacts = () => {
    return (
        <Layout>
            <Contact />
            <Infos />

            <div>
                <Partner bgColor={"white"} />
            </div>
        </Layout>
    );
};

export default Contacts;
