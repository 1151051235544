import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from "gatsby";
import Image from "../../templates/Image";

interface color {
    bgColor: any;
}

const Partner = ({ bgColor }: color) => {
    const dataDirectus = useStaticQuery(graphql`
        query getPartners {
            directus {
                partners {
                    id
                    name
                    url
                    logo {
                        id
                        imageFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                            name
                            publicURL
                        }
                    }
                }
            }
        }
    `);

    const partners = dataDirectus.directus.partners;
    const dataImage = partners.map((partner: any) => {
        return {
            src: partner.logo.imageFile?.publicURL,
            url: partner.url,
        };
    });

    return (
        <div>
            <div
                className="tw-text-center tw-w-full"
                style={{ backgroundColor: bgColor }}
            >
                <p className="tw-text-md sm:tw-text-2xl tw-font-bold  tw-pt-5 tw-pb-3 tw-text-primary">
                    Nos partenaires
                </p>
            </div>
            {/* <div className="tw-bg-white tw-w-full tw-p-5 tw-flex tw-flex-wrap tw-justify-around tw-space-y-1 sm:tw-space-y-0">
                <div className="tw-w-24 tw-rounded-lg tw-text-center">
                    <StaticImage
                        src="../../images/banque_mondial.png"
                        alt="banque"
                        className="tw-w-auto tw-h-auto sm:tw-w-[200px]"
                    />
                </div>
                <div className="tw-w-24 tw-rounded-lg tw-text-center">
                    <StaticImage
                        src="../../images/mclu.png"
                        alt="clu"
                        className="tw-w-auto tw-h-auto sm:tw-w-[200px]"
                    />
                </div>
                <div className="tw-w-24 tw-rounded-lg tw-text-center">
                    <StaticImage
                        src="../../images/pacoga.png"
                        alt="pacoga"
                        className="tw-w-auto tw-h-auto sm:tw-w-[200px]"
                    />
                </div>
                <div className="tw-w-24 tw-rounded-lg tw-text-center">
                    <StaticImage
                        src="../../images/bnetd.png"
                        alt="bnted"
                        className="tw-w-auto tw-h-auto sm:tw-w-[200px]"
                    />
                </div>
            </div> */}
            <div className="tw-bg-white tw-w-full tw-p-5 tw-flex tw-flex-wrap tw-justify-around tw-space-y-1 sm:tw-space-y-0">
                {dataImage &&
                    dataImage.map((image: any, index: number) => {
                        return (
                            <Link to={image.url} className="tw-w-40">
                                {" "}
                                <Image key={index} image={image.src} />
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};

export default Partner;
